<template>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col  cols="12"  sm="8"  md="4">
            <v-card class="elevation-12">
              <v-toolbar color="accent" light  flat>
                <v-toolbar-title class="white--text">Zaloguj się</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-alert v-if="message" type="error">
                    {{ message }}
                  </v-alert>
                  <v-row align="center" justify="center">
                    <v-col cols="1" >
                      <v-icon>mdi-account</v-icon>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="user.email" label="Login"  name="email"  type="email"/>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col cols="1" >
                      <v-icon>mdi-lock</v-icon>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="user.pass" id="password"  label="Password"  name="password"  type="password"/>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-col cols="1"/>
                <router-link :to="{name: 'recovery'}">Zapomniałem hasła</router-link>
                <v-spacer />
                <v-btn @click="login" color="primary accent-3" class="white--text" :x-large=true>Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

</template>

<script>
import {
  mdiAccount,
  mdiLock
} from '@mdi/js'

export default {
  props: {
    source: String,
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiLock
    },
    user: {
      email: null,
      pass: null
    },
    message: null
  }),
  methods:{
    login: function(){
      this.$store.dispatch( 'login', this.user )
      .then( () => this.$router.push('/') )
      .catch( e => this.message = e)
    }
  },
  created() {
    if( this.$store.state.token ) this.$router.push('/')
  }
}
</script>
