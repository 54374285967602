<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{ name }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Wygeneruj nowy
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="editedItem.project_id"
                      :items="projects"
                      :loading="isLoading"
                      :search-input.sync="search"
                      color="black"
                      hide-no-data
                      hide-selected
                      item-text="Description"
                      item-value="ID"
                      label="Wybierz projekt, dla którego chcesz wygenerować klucz"
                      prepend-icon="mdi-database-search"

                      @click="findProject"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Anuluj
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="generate"
              >
                Wygeneruj
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-toolbar>
    </template>
    <template v-slot:item.scope="{ item }">
      { {{ item.scope }} }
    </template>
    <template v-slot:item.project_id="{ item }">
      {{ item.name }}
    </template>
    <!-- <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template> -->
  </v-data-table>
</template>

<script>
import axios from 'axios';
import store from '@/store'
  export default {
    props: {
      type: String,
      name: String
    },
    data: () => ({
      url: store.getters.url,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Klucz', value: 'api_key' },
        { text: 'Projekt', value: 'project_id'},
        { text: 'Obszar działania', value: 'scope' },
        {
          text: 'Data wygenerowania',
          align: 'start',
          sortable: true,
          value: 'date_created',
        }
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        project_id: 0
      },
      defaultItem: {
        project_id: 0
      },
      isLoading: false,
      search: null,
      projects_entries: []
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nowy token' : 'Edit Item'
      },
      projects () {
        return this.projects_entries.map(entry => {
          const Description = `${ entry.name } (${ entry.first_name } ${ entry.last_name })`
          const ID = entry.customer_id;
          return Object.assign({}, entry, { Description, ID })
        })
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      search(val) {
        if( this.projects.length > 0 ) return;

        if(this.isLoading ) return;

        this.isLoading = true;

        axios({
          url: `${ this.url }/projects/get`,
          data: {
            params: {
              first_name: val,
              last_name: val,
              email: val
            }
          },
          method: 'POST'
        })
        .then( resp => {

          this.projects_entries = resp.data.list

        })
        .catch()
        .finally(() => (this.isLoading = false))

      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        axios({
          url: `${this.url}/keys/get`,
          data: {
            type: this.type.toLowerCase(),
            name: this.name.toLowerCase()
          },
          method: 'POST'
        })
        .then( resp => {
          if( resp.status == 200 ){
            this.items = resp.data.list;
          }
        })
      },

      generate() {
        axios({
          url: `${this.url}/keys/create`,
          data: {
            type: this.type.toLowerCase(),
            name: this.name.toLowerCase(),
            project_id: this.editedItem.project_id
          },
          method: 'POST'
        })
        .then( resp => {
          if( resp.status == 200 ){
            this.initialize();
            this.close();
          }
        })
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },

      findProject() {
        if( this.search === null ) this.search = ''
      }

    },
  }
</script>
