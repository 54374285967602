<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <ApiKeysTable :name="name" :type="type"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiKeysTable from '@/components/ApiKeysTable.vue'
export default {
  name: 'KeysList',
  components: {
    ApiKeysTable
  },
  data: () => ({
    type: 'theme',
    name: 'Sacrum'
  }),
}
</script>
