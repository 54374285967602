var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.name))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Wygeneruj nowy ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.projects,"loading":_vm.isLoading,"search-input":_vm.search,"color":"black","hide-no-data":"","hide-selected":"","item-text":"Description","item-value":"ID","label":"Wybierz projekt, dla którego chcesz wygenerować klucz","prepend-icon":"mdi-database-search"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"click":_vm.findProject},model:{value:(_vm.editedItem.project_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "project_id", $$v)},expression:"editedItem.project_id"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.generate}},[_vm._v(" Wygeneruj ")])],1)],1)],1)],1)]},proxy:true},{key:"item.scope",fn:function(ref){
var item = ref.item;
return [_vm._v(" { "+_vm._s(item.scope)+" } ")]}},{key:"item.project_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }